import React from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';
import './List-3Columns.scss';

const List3Columns = (props) => {
  const datasource = props.fields || {};
  if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
    const contentList = props.fields.contentList;
    const designVariant =
      typeof props.fields.designVariant === 'undefined' ? '' : props.fields.designVariant.value;

    // Center components when 2 or 4 items ie 2 columns instead of 3
    let columns = 'col-sm-6 col-lg-4 col-md-4';
    let itemsNumberPerRow = 3;
    if (contentList.length % 2 === 0 && contentList.length < 6) {
      columns = 'col-sm-6 col-lg-6 col-md-6';
      itemsNumberPerRow = 2;
    }

    // Add a row every 3 items to avoid vertical misalignement
    let contentRows = [];
    for (let i = 0; i < contentList.length; i++) {
      // loop through the contentList and add a row every 2 or 3 items depending on itemsNumberPerRow
      if (i % itemsNumberPerRow === 0) {
        contentRows.push(
          <div className="row" key={`rowList3Columns-${i / itemsNumberPerRow}`}>
            {contentList &&
              // slice the contentList in 3 items or 2 items depending on the number of columns
              contentList.slice(i, i + itemsNumberPerRow).map((listItem, index) => (
                <div className={columns} key={`listItem-${index}`}>
                  <div className="icon-box-group">
                    <div className="block-icon-box-left-icon">
                      <div className="icon pull-left">
                        <Image
                          field={listItem.fields.Icon}
                          editable={true}
                          vspace="15"
                          height="30"
                          width="30"
                        />
                      </div>
                      <div className="right-side">
                        <Text field={listItem.fields.Title} tag="h6" className="heading" />
                        <Text field={listItem.fields.Description} tag="p" className="content" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        );
      }
    }
    return (
      <div className={'section mt-7 mb-4 ' + designVariant}>
        <div className="container">{contentRows}</div>
      </div>
    );
  } else {
    return (
      <div className="component-no-datasource">
        {i18next.t('no-datasource') +
          ' ' +
          props.rendering.componentName +
          ' ' +
          i18next.t('component')}
      </div>
    );
  }
};
export default List3Columns;

import React from 'react';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './richTextComponent.scss';

class RichTextComponent extends React.Component {
  render() {
    return (
      <div className="richText">
        <RichText field={this.props.fields.Text} />
      </div>
    );
  }
}

export default withSitecoreContext()(RichTextComponent);

import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Moment from 'react-moment';
import { DotLoader } from 'react-spinners';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

import './recentPosts.scss';
const RecentPostsQuery = gqlLoader('./RecentPostsQuery.graphql');

class RecentPosts extends ReactComponent {
  render() {
    const datasource = this.props.fields || {};
    if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
      let PostsNumber = this.props.fields.PostsNumber.numberValue;
      if (
        PostsNumber === undefined ||
        PostsNumber === null ||
        isNaN(PostsNumber) ||
        PostsNumber === 0 ||
        PostsNumber < 0
      )
        PostsNumber = 3;

      function SortByPostUpdate(x, y) {
        return x.item.date.value === y.item.date.value
          ? 0
          : x.item.date.value < y.item.date.value
          ? 1
          : -1;
      }

      return (
        <div className={'recentPostsBg'}>
          <Query
            query={RecentPostsQuery}
            variables={{
              indexname: this.indexName,
              rootPath: 'sitecore/content/' + this.appName + '/',
            }}
          >
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <div
                    className="sweet-loading"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '100%',
                      minHeight: '100%',
                      margin: '0 auto',
                    }}
                  >
                    <DotLoader sizeUnit={'px'} size={25} color={'#8ac691'} loading={loading} />
                  </div>
                );
              if (error) return <div>Error: {error.message}</div>;
              return (
                <>
                  <h3>{this.props.fields.title.value}</h3>
                  <ol>
                    {data.search.results.items &&
                      data.search.results.items
                        .sort(SortByPostUpdate)
                        .slice(0, PostsNumber)
                        .map((post, index) => {
                          const englishVersion = this.props.sitecoreContext.language === 'en',
                            url = post.item.url,
                            fixedUrl = post.item.url.replace('/fr-FR/', '/');
                          return (
                            <li key={index}>
                              <NavLinkAdv to={englishVersion ? fixedUrl : url}>
                                <img
                                  src={post.item.postThumbnailImageSmallSquared.src}
                                  alt={post.item.postTitle.value}
                                />
                              </NavLinkAdv>
                              <NavLinkAdv to={englishVersion ? fixedUrl : url}>
                                <h4>{post.item.postTitle.value}</h4>
                              </NavLinkAdv>
                              <Moment format="MMM DD, YYYY">{post.item.date.value}</Moment>
                            </li>
                          );
                        })}
                  </ol>
                </>
              );
            }}
          </Query>
        </div>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
            i18next.t('component')}
        </div>
      );
    }
  }
}

export default withSitecoreContext()(RecentPosts);

import React from 'react';
import i18next from 'i18next';

import './HeadingSmallBanner.scss';

const HeadingSmallBanner = (props) => {
  const datasource = props.fields || {};
  if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
    return (
      <div
        className={'headingSmallBanner' + props.fields.designVariant.value}
        style={{
          background:
            `center / cover no-repeat url(` + props.fields.backgroundImage.value.src + `)`,
        }}
      >
        <h1>{props.fields.title.value}</h1>
      </div>
    );
  } else {
    return (
      <div className="component-no-datasource">
        {i18next.t('no-datasource') +
          ' ' +
          props.rendering.componentName +
          ' ' +
          i18next.t('component')}
      </div>
    );
  }
};

export default HeadingSmallBanner;

import React from 'react';
import './pagination.scss';

class Pagination extends React.Component {
  state = {
    pagination: [],
    numberOfPages: null,
  };

  componentDidMount() {
    this.Pagination(this.props.totalItems);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.totalItems !== this.props.totalItems || this.props.refreshPagination) {
      this.Pagination(this.props.totalItems);
      this.handleChangeState(1);
    }
  }

  handleChangeState(nb) {
    let data = {
      after: ((nb - 1) * parseInt(this.props.nbOfItemsToShow)).toString(),
      currentPage: nb,
    };
    this.props.onChange(data);
  }

  Pagination(total) {
    this.setState({ pagination: [] });
    let pagesNumber = total / parseInt(this.props.nbOfItemsToShow);
    let numberOfPages;
    if (pagesNumber % 2 === 0) {
      numberOfPages = pagesNumber + 1;
    } else {
      numberOfPages = Math.floor(pagesNumber) + 1;
    }
    this.setState({ numberOfPages: numberOfPages });
    
    let tempPagination = []
    for (let i = 0; i < numberOfPages; i++) {
      tempPagination.push(i + 1);
    }
    this.setState({pagination: tempPagination})
  }

    render() {
      
      return (
         
        <div className="pagination">
        {this.props.totalItems > 0 ? (
          this.state.pagination.map((nb, index) => {
            if (nb >= this.props.currentPage - 2 && nb <= this.props.currentPage + 2) {
              return (
                <React.Fragment key={index}>
                      <button
                          onClick={() => {
                              this.handleChangeState(nb);
                              window.scrollTo(0, 0);
                          }}
                      >
                          {nb ? nb : null}
                      </button>
                </React.Fragment>
              );
            }
          })
        ) : (
          <p>There is no item for your request.</p>
        )}
      </div>
    );
  }
}

export default Pagination;

import React from 'react';
import { Image, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Moment from 'react-moment';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

import './postContent.scss';

const PostContentQuery = gqlLoader('./PostContentQuery.graphql');

class PostContent extends ReactComponent {
  render() {
    const postContent = this.props.sitecoreContext.route.fields;
    const contextItem = this.props.sitecoreContext.route.itemId;

    let postNav = true;
    if (postContent.PostPrevious == null && postContent.PostNext == null) {
      postNav = false;
    }

    return (
      <>
        {postContent && (
          <>
            <div className="postContent">
              <Image
                loading="lazy"
                field={postContent.PostThumbnailImageBig}
                imageParams={{ w: 832 }}
                editable={true}
              />
              <h1>{postContent.PostTitle.value}</h1>
              <div className="date">
                <i className="fa fa-calendar" style={{ width: 18, height: 18 }} />
                <Query
                  query={PostContentQuery}
                  variables={{
                    rootPath: '/sitecore/content/' + this.appName + '/home/',
                    id: contextItem.split('-').join('').toLowerCase(),
                  }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return <div>{i18next.t('loading') + ': ' + loading.message}</div>;
                    if (error) return <div>{i18next.t('error') + ': ' + error.message}</div>;
                    const date = data.customSearch.results.items[0].item.date.value;
                    return <Moment format="MMM DD, YYYY">{date}</Moment>;
                  }}
                </Query>
              </div>

              <div className="richText">
                <RichText field={postContent.PostContent} />
              </div>

              {postNav && (
                <nav className="postNavigation">
                  {postContent.PostPrevious && (
                    <div className="previousPost">
                      <span>{i18next.t('previous-article')}</span>
                      <NavLinkAdv to={postContent.PostPrevious.url} rel="prev">
                        {postContent.PostPrevious.fields.PostTitle.value}
                      </NavLinkAdv>
                    </div>
                  )}
                  {postContent.PostNext && (
                    <div className="nextPost">
                      <span>{i18next.t('next-article')}</span>
                      <NavLinkAdv to={postContent.PostNext.url} rel="next">
                        {postContent.PostNext.fields.PostTitle.value}
                      </NavLinkAdv>
                    </div>
                  )}
                </nav>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default withSitecoreContext()(PostContent);

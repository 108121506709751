import React from 'react';
import { loader as gqlLoader } from 'graphql.macro';
import { Query } from 'react-apollo';
import { DotLoader } from 'react-spinners';

const ParentRouteQuery = gqlLoader('./query.graphql');
class ParentRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      ListParents: '',
    };
  }

  render() {
    return (
      <Query
        query={ParentRouteQuery}
        variables={{
          indexname: this.indexName,
          rootpath: '/sitecore/content/' + this.appName + '/',
          currrentContextItem: this.props.CurrentId,
        }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div
                className="sweet-loading"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '5rem',
                }}
              >
                <DotLoader
                  sizeUnit={'px'}
                  size={25}
                  color={'#8ac691'}
                  loading={!this.state.isLoaded}
                />
              </div>
            );
          if (error) return <div>Error: {error.message}</div>;
          return (
            <nav className={'breadcrumbs'}>
              {data.breadcrumbs &&
                JSON.parse(data.breadcrumbs).ListParents.map((item, index) => (
                  <React.Fragment key={index}>
                    <a href={item.Link}>{item.Text}</a>
                    {` / `}
                  </React.Fragment>
                ))}
              {this.props.CurrentItemName}
            </nav>
          );
        }}
      </Query>
    );
  }
}
export default ParentRoute;

import React from 'react';
import Moment from 'react-moment';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

import { Image } from '@sitecore-jss/sitecore-jss-react';
import './Post.scss';

function Post({ post }) {
  return (
    <div className="postsListCard">
      <Image
        loading="lazy"
        field={post.item.postThumbnailImageBig}
        imageParams={{ w: 650 }}
        editable={true}
      />
      <h2>
        {post.item.postIcon && post.item.postIcon.targetItem && (
          <Image field={post.item.postIcon.targetItem.iconImage} />
        )}
        {post.item.postTitle && post.item.postTitle.value}
      </h2>
      <div>
        <i className="fa fa-calendar" />
        <Moment format="MMM DD, YYYY">{post.item.date && post.item.date.value}</Moment>
      </div>
      <p>{post.item.postShortDescription && post.item.postShortDescription.value}</p>
      {post.item.externalUrl && post.item.externalUrl.value ? (
        <a target="_blank" rel="nofollow noopener" href={post.item.externalUrl.value}>
          {i18next.t('read-more-linkedin')}
        </a>
      ) : (
        <NavLinkAdv to={post.item && post.item.url}>{i18next.t('read-more')}</NavLinkAdv>
      )}
    </div>
  );
}

export default Post;

import React from 'react';
import { Image, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import { Query } from 'react-apollo';
import { DotLoader } from 'react-spinners';
import './listOfIngredients.scss';

const IngredientsQuery = gqlLoader('./query.graphql');
class ListOfIngredients extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  render() {
    return (
      <div className="row_custom_small list_cn">
        <Query
          query={IngredientsQuery}
          variables={{
            indexname: this.indexName,
            rootpath: '/sitecore/content/' + this.appName + '/',
          }}
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className="sweet-loading" style={{ marginTop: '50px', marginBottom: '50px' }}>
                  <DotLoader
                    sizeUnit={'px'}
                    size={50}
                    color={'#8986ca'}
                    loading={!this.state.isLoaded}
                  />
                </div>
              );
            if (error) return <div>Error: {error.message}</div>;
            return (
              data.search.results.items &&
              data.search.results.items.map((ingredient, index) => (
                <>
                  <button
                    className="col-md-3 link_ingredient"
                    data-toggle="modal"
                    data-target={`#img${index}`}
                    key={index}
                  >
                    <div>
                      <span className="img-cn">
                        <Image
                          loading="lazy"
                          field={ingredient.item && ingredient.item.image}
                          className="img_custom_full"
                        />
                      </span>
                      <strong className="title-cn">
                        <Text field={ingredient.item && ingredient.item.title} />
                      </strong>
                    </div>
                  </button>
                  <div
                    className="modal fade p-0"
                    id={`img${index}`}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="label_of_modal"
                    aria-hidden="true"
                    key={`modal-${index}`}
                  >
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                      <div className="modal-content ingre_modal">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="container-fluid">
                            <div className="row_custom_small">
                              <div className="col-md-4 text-center">
                                <Image
                                  loading="lazy"
                                  field={ingredient.item && ingredient.item.image}
                                  className="img_custom_full"
                                />
                              </div>
                              <div className="col-md-8 text-left">
                                <h4 className="popup-title">
                                  <Text field={ingredient.item && ingredient.item.title} />
                                </h4>
                                <p>
                                  <Text
                                    field={ingredient.item && ingredient.item.fullDescription}
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withSitecoreContext()(ListOfIngredients);

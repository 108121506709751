import React from 'react';
import {RichText} from '@sitecore-jss/sitecore-jss-react';

class SubSideTabs extends React.Component {

    componentDidMount() {

        // Closing bubble when we click anywhere on the page
        document.querySelector('html').addEventListener('click', function(e){

            if(!e.target.classList.contains('onglet') && !e.target.classList.contains('bubble')){
                document.querySelectorAll(".onglet").forEach(el => {
                    el.parentElement.classList.remove('active');
                })
            }
        })
        
    }


    handleClick = (e) => {
        if(!e.currentTarget.parentElement.classList.contains('active')){
            document.querySelectorAll(".onglet").forEach(el => {
                el.parentElement.classList.remove('active');
            })
        }
        
        e.currentTarget.parentElement.classList.toggle('active')
    }

    


 
    render() {
    
            return (
                <ul className="nav navtabs">
                    {this.props.list &&
                        this.props.list.map((listItem, index) => {
                            return (
                                <div key={index} className="wrapper">
                                    <li  
                                        title={listItem.fields.Title.value.replace(/<[^>]*>?/gm, '')}
                                        style={{backgroundImage: `url(` + listItem.fields.Icon.value.src + `)`}}
                                        className="onglet"
                                        onClick={(e) => this.handleClick(e)}>
                                    </li>
                                    <div className="bubble">
                                        <RichText field={listItem.fields && listItem.fields.Title}/>
                                        <RichText field={listItem.fields && listItem.fields.Content} tag={"p"}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </ul>
            );

        
    }
}

export default SubSideTabs;

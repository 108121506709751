import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from '../../ReactComponent';
import { Query } from 'react-apollo';
import { loader as gqlLoader } from 'graphql.macro';
import { DotLoader } from 'react-spinners';
import Pagination from '../../utils/Pagination';
import './postsList.scss';
import Post from '../Post/index.js';

const PostsListQuery = gqlLoader('./postsListQuery.graphql');

class PostsList extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      after: '0',
      nbOfItemsToShow: parseInt(this.props.fields.postPerPage.value),
      currentPage: 1
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(data) {
    this.setState({
      after: data.after,
      currentPage: data.currentPage
    });
  }


  render() {
    
    return (
      <Query
        query={PostsListQuery}
        variables={{
          indexname: this.indexName,
          rootPath: 'sitecore/content/' + this.appName + '/',
          first: this.state.nbOfItemsToShow,
          after: this.state.after,
        }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div
                className="sweet-loading"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '10rem',
                  margin: '0 auto',
                }}
              >
                <DotLoader
                  sizeUnit={'px'}
                  size={25}
                  color={'#8ac691'}
                  loading={!this.state.isLoaded}
                />
              </div>
            );

          if (error) return <div>Error: {error.message}</div>;

          let totalItems = data.search.results.totalCount;

          //const sortedData = data.search.results.items.sort(function(a, b){return parseInt(b.item.date.value) - parseInt(a.item.date.value)})

          return (
            <>
              <div className="postsList">
                {data && data.search.results.items ? (
                  data.search.results.items.map((post, index) => {
                    return <Post key={`post-${index}`} post={post} />;
                  })
                ) : (
                  <p>There is no item to show.</p>
                )}
              </div>
              <Pagination
                totalItems={totalItems}
                nbOfItemsToShow={this.state.nbOfItemsToShow}
                currentPage={this.state.currentPage}
                onChange={this.handleChange}
              />
            </>
          );
        }}
      </Query>
    );
  }
}

export default withSitecoreContext()(PostsList);

import React from 'react';
import { Image, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

import './FeaturedPost.scss';

const FeaturedPosts = (props) => {
  const datasource = props.fields || {};
  if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
    const { FeaturedPostsContentList, designVariant } = props.fields;

    return (
      <>
        <div className={'FeaturedPosts' + designVariant.value}>
          <h2 className="">{props.fields.Title.value}</h2>
          <ul>
            {FeaturedPostsContentList &&
              FeaturedPostsContentList.map((listItem, index) => (
                <li key={`sharedListItem-${index}`}>
                  <Image loading="lazy" field={listItem.fields.Icon} />
                  <h3 className="">{listItem.fields.title.value}</h3>
                  {/* RichText pour la catégorie */}
                  <RichText field={listItem.fields.category?.value} />
                  <div className="richText">
                    <RichText field={listItem.fields.content} />
                  </div>
                  <NavLinkAdv to={listItem.fields.PostLink && listItem.fields.PostLink.value.href}>
                    Lire plus
                  </NavLinkAdv>
                </li>
              ))}
          </ul>
        </div>
      </>
    );
  } else {
    return (
      <div className="component-no-datasource">
        {i18next.t('no-datasource') +
          ' ' +
          props.rendering.componentName +
          ' ' +
          i18next.t('component')}
      </div>
    );
  }
};
export default withSitecoreContext()(FeaturedPosts);

import React from 'react';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';

import './footerV2.scss';
import NavLinkAdv from '../../utils/NavLinkAdv';
class FooterV2 extends React.Component {
  componentDidMount() {
    const originalFlags = [...document.querySelectorAll('.overHead a')].slice(2, 4);
    const originalLocation = document.querySelector('.overHead a:nth-child(2)');
    const newLocation = document.querySelector('.flags');

    const moveFlags = () => {
      if (window.matchMedia('(max-width: 513px)').matches) {
        originalFlags.forEach((flag) => {
          newLocation.appendChild(flag);
          if (newFlags.length < 2) {
            newFlags.push(flag);
          }
        });
      }
    };

    let newFlags = [];
    moveFlags();
    window.addEventListener('resize', () => {
      moveFlags();
      if (!window.matchMedia('(max-width: 513px)').matches) {
        newFlags.forEach((flag) => {
          originalLocation.parentNode.insertBefore(flag, originalLocation.nextSibling);
        });
        newFlags.length = 0;
      }
    });
  }

  render() {
    const { SocialLinks } = this.props.fields ? this.props.fields : '';
    const { MenuLinks } = this.props.fields ? this.props.fields : '';

    return (
      <footer>
        <ul>
          {this.props.fields.FooterMultilevel !== undefined &&
            this.props.fields.FooterMultilevel.map(
              (footerMulti) =>
                footerMulti.fields &&
                footerMulti.fields.HeaderMenu.map((mainMenu, mainindex) => (
                  <li id={`mainind_${mainindex}`} key={`foot-${mainindex}`}>
                    {mainMenu.fields.Title &&
                      mainMenu.fields.InternalLink &&
                      mainMenu.fields.InternalLink.value.href !== '' && (
                        <NavLinkAdv
                          to={
                            mainMenu.fields.InternalLink && mainMenu.fields.InternalLink.value.href
                          }
                          className="first_link"
                          rel="noopener noreferrer"
                        >
                          <Text field={mainMenu.fields.Title} />
                        </NavLinkAdv>
                      )}
                    {mainMenu.fields.SubMenuLinks.length > 0 && (
                      <ul id={`subind_${mainindex}`} className="sub-menu">
                        {mainMenu.fields.SubMenuLinks.map((subMenu, subindex) => (
                          <li key={`sub_${subindex}`} className="menu-item">
                            {subMenu.fields.InternalLink &&
                              subMenu.fields.InternalLink.value.href !== '' && (
                                <NavLinkAdv
                                  to={
                                    subMenu.fields.InternalLink &&
                                    subMenu.fields.InternalLink.value.href
                                  }
                                >
                                  <Text field={subMenu.fields.Title} />
                                </NavLinkAdv>
                              )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))
            )}
        </ul>
        <div className="flags"></div>
        <div className="contact">
          <div>
            {SocialLinks &&
              SocialLinks.map((listItem, index) => (
                <React.Fragment key={index}>
                  {listItem.fields.InternalLink && (
                    <NavLinkAdv
                      to={listItem.fields.InternalLink && listItem.fields.InternalLink.value.href}
                      target="_blank"
                      key={index}
                      rel="noopener noreferrer"
                    >
                      <Image field={listItem.fields.Image} className="img_custom_full" />
                    </NavLinkAdv>
                  )}
                </React.Fragment>
              ))}
          </div>
          <div>
            <span>{this.props.fields && this.props.fields.Text.value}</span>
            <div>
              <i className="fas fa-phone" /> {i18next.t('ConsumerLabel')} :{' '}
              <a
                href={'tel:' + this.props.fields.TelephoneNumber.value}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text field={this.props.fields.TelephoneNumber} />
              </a>
            </div>
            <div>
              <i className="far fa-envelope" aria-hidden="true" /> {i18next.t('EmailLabel')} :{' '}
              <a
                href={'mailto:' + this.props.fields.EmailAddress.value}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text field={this.props.fields.EmailAddress} />
              </a>
            </div>
          </div>
        </div>
        <p className="copyright">
          <span>{i18next.t('CopyrightLabel')}</span>
          {MenuLinks &&
            MenuLinks.map((listItem, index) => (
              <React.Fragment key={index}>
                {listItem.fields.InternalLink.value.href && (
                  <NavLinkAdv
                    to={listItem.fields.InternalLink && listItem.fields.InternalLink.value.href}
                    key={index}
                    rel="noopener noreferrer"
                  >
                    <Text field={listItem.fields.Title} />
                  </NavLinkAdv>
                )}
              </React.Fragment>
            ))}
          <button id="ot-sdk-btn" className="ot-sdk-show-settings">
            {i18next.t('CookieConfigurationLabel')}
          </button>
        </p>
      </footer>
    );
  }
}

export default withSitecoreContext()(FooterV2);

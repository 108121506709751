import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { DotLoader } from 'react-spinners';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

import './List3ColumnsTitleDynamic.scss';

const List3ColumnsTitleDynamicQuery = gqlLoader('./List3ColumnsTitleDynamicQuery.graphql');

class List3ColumnsTitleDynamic extends ReactComponent {
  render() {
    const datasource = this.props.fields || {};
    const defaultTag = '{00000000000000000000000000000000}';
    if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
      const postTag =
        this.props.fields.tag !== undefined
          ? this.props.fields.tag.id.replace(/-/g, '')
          : defaultTag;

      function SortByPostUpdate(x, y) {
        return x.item.date.value === y.item.date.value
          ? 0
          : x.item.date.value < y.item.date.value
          ? 1
          : -1;
      }

      return (
        <>
          {/* <div className="List3ColumnsTitleDynamic" style={{ height: 150 }}>
            <Text field={this.props.fields.title} tag="h2" />
            <Text field={this.props.fields.subtitle} tag="p" />
            <div className="separator-inherit-theme">
              <hr className="mini-sep" />
            </div>
          </div> */}
          <Query
            query={List3ColumnsTitleDynamicQuery}
            variables={{ indexname: this.indexName, tagName: postTag }}
          >
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <div className="section mt-2 pb-4">
                    <div className="container">
                      <div className="row">
                        <div>
                          <div
                            className="sweet-loading"
                            style={{ marginTop: '50px', marginBottom: '50px' }}
                          >
                            <DotLoader
                              sizeUnit={'px'}
                              size={50}
                              color={'#8ac691'}
                              loading={loading}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              if (error)
                return (
                  <div className="section mt-2 pb-4">
                    <div className="container">
                      <div className="row">
                        <div>{i18next.t('error') + ': ' + error.message}</div>
                      </div>
                    </div>
                  </div>
                );

              if (
                data == null ||
                data.customSearch == null ||
                data.customSearch.results == null ||
                data.customSearch.results.items.length === 0
              )
                return (
                  <div className="section mt-2 pb-4">
                    <div className="container">
                      <div className="row">
                        <div>{i18next.t('no-data')}</div>
                      </div>
                    </div>
                  </div>
                );
              return (
                <ul className="List3ColumnsTitleDynamic">
                  {data.customSearch.results.items &&
                    data.customSearch.results.items
                      .sort(SortByPostUpdate)
                      .slice(0, 6)
                      .map((post, index) => (
                        <li key={index}>
                          <NavLinkAdv to={post.item.url}>
                            <img
                              src={post.item.postThumbnailImageMedium.src}
                              alt=""
                              width="370"
                              height="239"
                            />
                            <h2>{post.item.postTitle.value}</h2>
                            <Text field={post.item.postShortDescription} tag="p" />
                          </NavLinkAdv>
                        </li>
                      ))}
                </ul>
              );
            }}
          </Query>
        </>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
            i18next.t('component')}
        </div>
      );
    }
  }
}

export default withSitecoreContext()(List3ColumnsTitleDynamic);

import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ParentRoute from '../../utils/ParentRoute';
import './Breadcrumbs.scss';

const Breadcrumbs = (props) => {
  return (
    <ParentRoute
      CurrentId={props.sitecoreContext.itemId}
      CurrentItemName={props.sitecoreContext.route.displayName}
    />
  );
};
export default withSitecoreContext()(Breadcrumbs);

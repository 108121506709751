import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { DotLoader } from 'react-spinners';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import i18next from 'i18next';

import './FeaturedPostsDynamic.scss';
import FeaturedPostDynamic from '../FeaturedPostDynamic';

const FeaturedPostsDynamicQuery = gqlLoader('./FeaturedPostsDynamicQuery.graphql');

class FeaturedPostsDynamic extends ReactComponent {
  render() {
    const datasource = this.props.fields || {};
    if (
      datasource !== undefined &&
      Object.getOwnPropertyNames(datasource).length >= 1 &&
      this.props.sitecoreContext.pageState !== 'edit'
    ) {
      const { FeaturedPostsContentList } = this.props.fields;

      return (
        <div className="featuredPostsDynamic">
          <h2>{this.props.fields.Title.value}</h2>
          <p className={this.props.fields.Text.value !== '' ? 'text-center' : 'd-none'}>
            <Text field={this.props.fields.Text} />
          </p>
          <ul>
            {FeaturedPostsContentList?.map((listItem, index) => (
              <Query
                key={index}
                query={FeaturedPostsDynamicQuery}
                variables={{
                  indexname: this.indexName,
                  rootPath: 'sitecore/content/' + this.appName + '/',
                  id: listItem.id.split('-').join(''),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading)
                    return (
                      <div className="section mt-2 pb-4">
                        <div className="container">
                          <div className="d-flex">
                            <div
                              className="sweet-loading"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minWidth: '100%',
                                minHeight: '10rem',
                                margin: '0 auto',
                              }}
                            >
                              <DotLoader
                                sizeUnit={'px'}
                                size={25}
                                color={'#8ac691'}
                                loading={loading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  if (error) return <div className="row">Error: {error.message}</div>;
                  return data.customSearch.results?.items
                    .filter(
                      (item) => item.item.id === listItem.id.split('-').join('').toUpperCase()
                    )
                    .map((item) => {
                      return <FeaturedPostDynamic post={item} key={index} listItem={listItem} />;
                    });
                }}
              </Query>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
            i18next.t('component')}
        </div>
      );
    }
  }
}

export default withSitecoreContext()(FeaturedPostsDynamic);

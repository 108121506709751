import React from 'react';
import { Image, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './headermultilevel.scss';
import ReactComponent from '../../ReactComponent';
import NavLinkAdv from '../../utils/NavLinkAdv/index';
import { HashLink } from 'react-router-hash-link';
class HeaderMultilevel extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.addClassMobile = this.addClassMobile.bind(this);
  }

  componentDidMount() {
    // Closes the menu when the user clicks on a link
    const burgerMenu = document.querySelector('.slicknav_icon');
    const links = document.querySelectorAll('.slicknav_nav a');
    links.forEach((link) => {
      link.addEventListener('click', () => {
        burgerMenu.click();
      });
    });

    // Toggles mobile menu on resize
    const mobileMenu = document.querySelector('.slicknav_nav');
    window.addEventListener('resize', () => {
      if (
        window.matchMedia('(min-width: 1051px)').matches &&
        mobileMenu.classList.contains('show')
      ) {
        ['show', 'hide'].forEach((element) => mobileMenu.classList.toggle(element));
      } else if (
        window.matchMedia('(max-width: 1050px)').matches &&
        mobileMenu.classList.contains('hide')
      ) {
        ['show', 'hide'].forEach((element) => mobileMenu.classList.toggle(element));
      }
    });
  }

  addClassMobile() {
    this.setState({
      menu: !this.state.menu,
    });
  }

  scrollOffset = (el) => {
    window.scrollBy({ top: el.getBoundingClientRect().top + window.pageYOffset - 50 });
  };

  setSearchState(listItem, productPageLink) {
    let categoryList = [];
    let categoryNameList = [];
    categoryList.push(listItem.id.replace(/-/g, ''));
    categoryNameList.push(listItem.fields.title.value);
    sessionStorage.setItem('filterCategories', JSON.stringify(categoryList));
    sessionStorage.setItem('filterCategoriesName', JSON.stringify(categoryNameList));
    window.location.href =
      productPageLink &&
      productPageLink.value.href + '?tagId=' + listItem.id.split('-').join('').toLowerCase();
  }

  render() {
    // Manages the H1 in the logo
    const routesWithoutH1 = ['home', 'Nos expertises', 'Nos ingrédients'];
    const routeNeedsAnH1 = routesWithoutH1.includes(this.props.sitecoreContext.route.name);

    return (
      <>
        <div className="nav-hd">
          {/* Mobile Menu */}
          <div className="logoWrapper">
            <div className="slicknav_menu">
              <div aria-haspopup="true" tabIndex="0" className="slicknav_btn slicknav_collapsed">
                <span
                  className={
                    this.state.menu
                      ? 'slicknav_icon collapsed changeHamburger'
                      : 'slicknav_icon collapsed'
                  }
                  data-toggle="collapse"
                  data-target="#navigation"
                  onClick={this.addClassMobile}
                >
                  <span className="slicknav_icon-bar"></span>
                  <span className="slicknav_icon-bar"></span>
                  <span className="slicknav_icon-bar"></span>
                </span>
              </div>
            </div>
            <NavLinkAdv to="/" className="logo">
              {routeNeedsAnH1 ? (
                <h1>
                  <Image
                    loading="lazy"
                    field={this.props.fields.SiteLogo}
                    width="278"
                    height="30"
                    className="img_custom_full"
                    alt="Novéal - Ingredients for Beauty"
                  />
                </h1>
              ) : (
                <Image
                  loading="lazy"
                  field={this.props.fields.SiteLogo}
                  width="278"
                  height="30"
                  className="img_custom_full"
                  alt="Logo Novéal"
                />
              )}
            </NavLinkAdv>
          </div>

          {/* Desktop Menu */}
          <div className="nav-cn">
            <nav>
              <ul className="menu-hd">
                {this.props.fields.HeaderMenu &&
                  this.props.fields.HeaderMenu.map((mainMenu, mainindex) => (
                    <li
                      className={`${
                        mainMenu.fields.SubMenuLinks.length > 0 ? 'menu-item-has-children ' : ''
                      }`}
                      key={mainindex}
                    >
                      {mainMenu.fields.InternalLink.value.href !== '' &&
                        mainMenu.fields.InternalLink.value.href && (
                          <NavLinkAdv
                            to={
                              mainMenu.fields.InternalLink &&
                              mainMenu.fields.InternalLink.value.href
                            }
                          >
                            <Text field={mainMenu.fields && mainMenu.fields.Title} />
                          </NavLinkAdv>
                        )}
                      {mainMenu.fields.SubMenuLinks.length > 0 && (
                        <ul className="sub-menu">
                          {mainMenu.fields.SubMenuLinks &&
                            mainMenu.fields.SubMenuLinks.map((submenu, subindex) => {
                              return (
                                <li
                                  className={`menu-item ${
                                    mainMenu.fields.ShowSubmenu.value === '1'
                                      ? 'menu-item-has-children'
                                      : ''
                                  } ${
                                    mainMenu.fields.SubMenuLinks.length === subindex + 1
                                      ? 'no_border__custom'
                                      : ''
                                  }`}
                                  key={subindex}
                                >
                                  {submenu.fields.InternalLink.value.href !== '' &&
                                    submenu.fields.InternalLink.value.href && (
                                      <HashLink
                                        smooth
                                        to={this.getUrlEmbeded(
                                          submenu.fields.InternalLink.value.href + "#" + submenu.fields.InternalLink.value.anchor
                                        )}
                                        scroll={(el) => this.scrollOffset(el)}
                                      >
                                        {submenu.fields?.Title.value}
                                      </HashLink>
                                    )}
                                </li>
                              );
                            })}
                        </ul>
                      )}
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
        </div>
        <ul className="slicknav_nav slicknav_hidden collapse" id="navigation">
          {this.props.fields.HeaderMenu &&
            this.props.fields.HeaderMenu.map((mainMenu, mainindex) => (
              <div key={mainindex}>
                <li
                  className={`menu-item slicknav_parent slicknav_collapsed ${
                    mainMenu.fields.SubMenuLinks.length === 0 ? 'empty' : ''
                  }`}
                  aria-haspopup="true"
                  tabIndex="0"
                >
                  {mainMenu.fields.InternalLink.value.href !== '' &&
                    mainMenu.fields.InternalLink.value.href && (
                      <HashLink
                        smooth
                        to={this.getUrlEmbeded(
                          mainMenu.fields.InternalLink && mainMenu.fields.InternalLink.value.href + "#" + mainMenu.fields.InternalLink.value.anchor
                        )}
                        scroll={(el) => this.scrollOffset(el)}
                      >
                        <Text field={mainMenu.fields && mainMenu.fields.Title} />
                      </HashLink>
                    )}
                  {mainMenu.fields.SubMenuLinks.length > 0 && (
                    <ul
                      className="sub-menu slicknav_hidden collapse"
                      role="menu"
                      aria-hidden="true"
                      id={`menu`}
                    >
                      {mainMenu.fields.SubMenuLinks &&
                        mainMenu.fields.SubMenuLinks.map((submenu, subindex) => (
                          <li
                            className=" child-nav slicknav_collapsed slicknav_parent "
                            key={subindex}
                            aria-haspopup="true"
                            tabIndex="0"
                          >
                            {submenu.fields.InternalLink.value.href !== '' &&
                              submenu.fields.InternalLink.value.href && (
                                <HashLink
                                  smooth
                                  to={this.getUrlEmbeded(
                                    submenu.fields.InternalLink &&
                                      submenu.fields.InternalLink.value.href  + "#" + submenu.fields.InternalLink.value.anchor
                                  )}
                                  scroll={(el) => this.scrollOffset(el)}
                                >
                                  {submenu.fields && submenu.fields.Title.value}
                                </HashLink>
                              )}
                          </li>
                        ))}
                    </ul>
                  )}
                </li>
              </div>
            ))}
        </ul>
      </>
    );
  }
}

export default withSitecoreContext()(HeaderMultilevel);

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';

const ListHorizontal = (props) => {
  const { HorizontalContentList } = props.fields || {};
  if (
    HorizontalContentList !== undefined &&
    Object.getOwnPropertyNames(HorizontalContentList).length >= 1
  ) {
    return (
      <div className={'section pt-7 pb-4 '}>
        <div className="container">
          <div className="row">
            {HorizontalContentList &&
              HorizontalContentList.map((listItem, index) => (
                <div className="col  ">
                  <NavLink key={`sharedListItem-${index}`} to={listItem.fields.PostLink.value.href}>
                    <div className="icon-circle">
                      <div className="icon-circle-inner">
                        <i>
                          {' '}
                          <Image loading="lazy" field={listItem.fields.Icon} />
                        </i>
                      </div>
                    </div>
                    <h4 className="text-center mt-3 mb-3">
                      <Text field={listItem.fields.title} />
                    </h4>
                    <div className="text-center mb-3">
                      <RichText field={listItem.fields.content} />
                    </div>
                  </NavLink>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="component-no-datasource">
        {i18next.t('no-datasource') +
          ' ' +
          props.rendering.componentName +
          ' ' +
          i18next.t('component')}
      </div>
    );
  }
};

export default ListHorizontal;

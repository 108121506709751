import React from 'react';
import {Text, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from "../../utils/NavLinkAdv";

const SubMenu = (props) =>  {
    const subMenuContentList = props.subMenuContentList;
    return (
        <div className="footer-widget-col col-md-3 col-sm-6">
            <div className="widget">
                <h3 className="widget-title"><span><Text field={props.title} /></span></h3>
                <div className="textwidget">
                    <ul>
                        {subMenuContentList &&
                        subMenuContentList.map((listItem, index) => (
                            <li key={`sharedListItem-${index}`} >
                                <NavLinkAdv to={listItem.fields.InternalLink.value.href}>{listItem.fields.InternalLink.value.text}</NavLinkAdv>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default withSitecoreContext()(SubMenu);

import React from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import './rowBlock.scss';
import NavLinkAdv from '../../utils/NavLinkAdv';
class RowBlock extends React.Component {
  componentDidMount() {
    const rowBlocks = document.querySelectorAll('.rowBlock');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animation');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    rowBlocks.forEach((rowBlock) => {
      observer.observe(rowBlock);
    });
  }

  render() {
    let leftcssStyle = 'img-cn';
    let rightcssStyle = 'text-cn';

    if (this.props.fields.blockStyle !== null) {
      leftcssStyle = this.props.fields.blockStyle.fields.leftcssStyle.value;
      rightcssStyle = this.props.fields.blockStyle.fields.rightcssStyle.value;
    }

    const designVariant = this.props.fields.designVariant.value;
    return (
      <div
        style={{
          flexDirection: leftcssStyle.includes('text-cn') && 'row-reverse',
        }}
        className="rowBlock"
        id={this.props.fields.blockAnchorID.value}
      >
        <div
          className={`imgContainer
        ${designVariant.includes('Decoration') ? 'decorationMargin' : ''}`}
        >
          <div className="imgWrapper">
            {this.props.fields.blockLink.value.href !== '' ? (
              <NavLinkAdv
                to={`${this.props.fields.blockLink.value.href}`}
                rel="noopener noreferrer"
              >
                <Image loading="lazy" field={this.props.fields && this.props.fields.blockImage} />
              </NavLinkAdv>
            ) : (
              <Image loading="lazy" field={this.props.fields && this.props.fields.blockImage} />
            )}
            {designVariant.includes('greenDecoration') && <div className="greenDecoration"></div>}
            {designVariant.includes('blueDecoration') && <div className="blueDecoration"></div>}
          </div>
        </div>
        <div
          className={`textContainer ${
            leftcssStyle.includes('text-cn') ? 'marginRight' : 'marginLeft'
          }
          ${designVariant.includes('blueBackground') ? 'blueBackground' : ''}
          ${designVariant.includes('fuchsiaBackground') ? 'fuchsiaBackground' : ''}`}
        >
          {' '}
          {this.props.fields.blockLink.value.href !== '' ? (
            <NavLinkAdv to={`${this.props.fields.blockLink.value.href}`} rel="noopener noreferrer">
              <h2>{this.props.fields.blockTitle.value}</h2>
            </NavLinkAdv>
          ) : (
            <h2>{this.props.fields.blockTitle.value}</h2>
          )}
          <RichText field={this.props.fields && this.props.fields.blockContent} />
        </div>
      </div>
    );
  }
}

export default RowBlock;

import packageJson from '../../../package.json';
import SCJSS from '../../temp/config';
class Context {
  static getIndexName(args) {
    return (
      'sitecore_' +
      args.sitecoreContext.route.databaseName +
      '_' +
      packageJson.config.appName.split('-').join('_') +
      '_index'
    );
    //return "sitecore_master_jss_master_index";
  }

  static getGraphQLEndPoint() {
    return SCJSS.graphQLEndpoint;
  }

  static getAppName() {
    return packageJson.config.appName;
  }

  static getUrlSer() {
    return SCJSS.sitecoreApiHost;
  }
}

export default Context;

import React from 'react';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

import './block-ImageRight.scss';

const BlockImageRight = (props) => {
  const datasource = props.fields || {};
  if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
    return (
      <div
        className={'carriers ' + props.fields.designVariant.value + ''}
        style={{
          backgroundImage: `url(` + props.fields.BackgroundImage.value.src + `)`,
        }}
      >
        <h1>{props.fields.Title.value}</h1>
        <Text tag="p" field={props.fields.Description} />
        <NavLinkAdv to={props.fields.Link && props.fields.Link.value.href} className="">
          <span>{props.fields.Link.value.title}</span>
          {/* <i className="fa fa-long-arrow-right"></i> */}
        </NavLinkAdv>

        <div className="">
          <div className="">
            <Image
              loading="lazy"
              field={props.fields.Image}
              style={{ maxHeight: '368px', maxWidth: '420px' }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="component-no-datasource">
        {i18next.t('no-datasource') +
          ' ' +
          props.rendering.componentName +
          ' ' +
          i18next.t('component')}
      </div>
    );
  }
};

export default withSitecoreContext()(BlockImageRight);

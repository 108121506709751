import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../utils/NavLinkAdv';

const SubFooterMenu = (props) => {
  const subFooterList = props.subFooterList;
  return (
    <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 sepCol">
      <h4>
        <Text field={props.title} />
      </h4>
      <ul className="footerUl">
        {subFooterList &&
          subFooterList.map((listItem, index) => (
            <li key={`sharedListItem-${index}`}>
              <i className="ion-ios-arrow-right"></i>
              <NavLinkAdv to={listItem.fields.InternalLink.value.href}>
                {listItem.fields.InternalLink.value.href}
              </NavLinkAdv>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default withSitecoreContext()(SubFooterMenu);

import React from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import Moment from 'react-moment';
import { DotLoader } from 'react-spinners';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

const RelatedPostsQuery = gqlLoader('./RelatedPostsQuery.graphql');

class RelatedPosts extends ReactComponent {
  render() {
    let postNb = 2;
    //if (this.props.sitecoreContext.route.templateName === "PostRoute") postNb = 3;
    const tagId = this.props.sitecoreContext.route.fields.PostMainTag.id;
    let postTag = tagId.replace(/-/g, '');

    return (
      <Query
        query={RelatedPostsQuery}
        variables={{
          indexname: this.indexName,
          rootPath: 'sitecore/content/' + this.appName + '/',
          $postnb: postNb,
          tagName: postTag,
        }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div className="sweet-loading" style={{ marginTop: '50px', marginBottom: '50px' }}>
                <DotLoader sizeUnit={'px'} size={50} color={'#8ac691'} loading={loading} />
              </div>
            );
          if (error) return <p>{error.message}</p>;
          return (
            <div className={'related-post container ' + this.props.fields.designVariant.value}>
              <div className="related-post-title">
                <h3>
                  <span>
                    <Text field={this.props.fields.title} />
                  </span>
                </h3>
              </div>
              <div className="row related-post-items">
                {data.search.results.items &&
                  data.search.results.items.slice(0, 2).map((post, index) => (
                    <div className="col-sm-6" href={post.item.url} key={`post-` + index}>
                      <div className="related-post-item">
                        <div className="entry-featured">
                          <NavLinkAdv to={post.item.url}>
                            <img
                              loading="lazy"
                              src={post.item.postThumbnailImageMedium.src}
                              alt={post.item.postThumbnailImageMedium.alt}
                              width="970"
                              height="540"
                            />
                          </NavLinkAdv>
                        </div>
                        <h4 className="post-title">
                          <NavLinkAdv to={post.item.url}>{post.item.postTitle.value}</NavLinkAdv>
                        </h4>
                        <div className="entry-meta icon-meta">
                          <span className="meta-date">
                            <i className="fa fa-calendar"></i>
                            <span>
                              <Moment format="MMM DD, YYYY">{post.item.date.value}</Moment>
                            </span>
                          </span>
                          {/* <span className="meta-comment">
			                        <i className="fa fa-comment-o"></i>
			                        <NavLink to="#" className="meta-comments">0 Comment</NavLink>
			                      </span> */}
                        </div>
                        <div className="excerpt">
                          <p>{post.item.postShortDescription.value}</p>
                        </div>
                        <div className="readmore-link">
                          <NavLinkAdv to={post.item.url}>{i18next.t('read-more')}</NavLinkAdv>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withSitecoreContext()(RelatedPosts);

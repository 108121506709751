import React, { useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import './overhead.scss';
import NavLinkAdv from '../../utils/NavLinkAdv';

const OverHead = (props) => {
  const { overHeadBannerContentList } = props.fields;

  useEffect(() => {
    // Removes "target" and "rel" attributes from a few select links
    const languageLinks = document.querySelectorAll(
      '.overHead [target="_blank"]:not([href^="mailto"])'
    );
    ['target', 'rel'].forEach((attribute) =>
      languageLinks.forEach((link) => link.removeAttribute(attribute))
    );
  });

  return (
    <div className="overHead">
      {overHeadBannerContentList &&
        overHeadBannerContentList.map((listItem, index) => (
          <React.Fragment key={index}>
            {listItem.fields.InternalLink && (
              <NavLinkAdv to={listItem.fields.InternalLink.value.href} key={index}>
                <i className={listItem.fields.IconClass.value} aria-hidden="true" />
                <Text field={listItem.fields.Title} />
              </NavLinkAdv>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default OverHead;

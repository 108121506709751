import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './SideBySideCarousel.scss';

class SideBySideCarousel extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      fade: true,
    };

    const Slides = this.props.fields.Slides;

    return (
      <div className={'SideBySideCarousel ' + this.props.fields.designVariant.value}>
        <Slider {...settings}>
          {Slides &&
            Slides.map((slideItem, index) => (
              <div key={index} className="slideItem" data-dot={index + 1}>
                <div className="leftPart">
                  <div
                    className="imgContent"
                    style={{ backgroundImage: `url(` + slideItem.fields.Image.value.src + `)` }}
                  ></div>
                </div>
                <div className="rightPart">
                  {/* <img src={dnaImg} alt="" /> */}
                  <RichText field={slideItem.fields.Title} tag="h1" />
                  <div className="topDecoration"></div>
                  <RichText className="rightPartContent" field={slideItem.fields.Texte} />
                  <div className="bottomDecoration"></div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    );
  }
}

export default SideBySideCarousel;

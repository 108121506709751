import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import SubTabs from "../SubTabs";

class Tabs extends React.Component {

    render(){
      return (
          <div className="tabbable commerce-tabs container">
                <SubTabs
                  list={this.props.fields && this.props.fields.tabsList}
                />
          </div>
      );
    }
}

export default withSitecoreContext()(Tabs);

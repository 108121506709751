import React from 'react';
import Moment from 'react-moment';
import './globalSearch.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from '../../ReactComponent';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

import { Query } from 'react-apollo';
import { loader as gqlLoader } from 'graphql.macro';
import { DotLoader } from 'react-spinners';
import Pagination from '../../utils/Pagination';

const GlobalSearchQuery = gqlLoader('./globalSearchQuery.graphql');

class GlobalSearch extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      after: '0',
      currentPage: 1,
      nbOfItemsToShow: parseInt(this.props.fields.itemsPerPage.value),
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.resetKeyword = this.resetKeyword.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const url = window.location.href;
    let keyword = '';

    if (url.includes('?search=')) {
      keyword = url.substring(url.indexOf('=') + 1);
      if (keyword.includes('#')) keyword = keyword.replace('#', '');
      this.setState({
        keyword: keyword,
      });
    }
  }

  handleSearch(e) {
    this.setState({
      keyword: e.target.value,
    });
  }

  resetKeyword() {
    this.setState({
      keyword: '',
    });
  }

  handleChange(data) {
    this.setState({
      after: data.after.toString(),
      currentPage: data.currentPage,
    });
  }

  render() {
    const datasource = this.props.fields || {};
    if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
      const designVariant =
        typeof this.props.fields.designVariant === 'undefined'
          ? ''
          : this.props.fields.designVariant.value;

      return (
        <div className="globalSearch">
          <div
            className={'headingSmallBanner' + designVariant}
            style={{
              background:
                `center / cover no-repeat url(` + this.props.fields.backgroundImage.value.src + `)`,
            }}
          >
            <h1>{this.props.fields.title.value}</h1>
          </div>
          <form className="searchBar">
            <input
              type="search"
              placeholder="Search.."
              value={this.state.keyword}
              onChange={this.handleSearch}
            />
            <input
              type="submit"
              id="globalSearchSubmit"
              className="hidden"
              name="submit"
              value="Search"
            />
            <div>
              <i className="fa fa-search" />
              <i
                onClick={() => this.resetKeyword()}
                className={this.state.keyword === '' ? 'hidden' : 'fa fa-close'}
              />
            </div>
          </form>
          <Query
            query={GlobalSearchQuery}
            variables={{
              indexname: this.indexName,
              rootPath: 'sitecore/content/' + this.appName + '/',
              first: this.state.nbOfItemsToShow,
              after: this.state.after,
              keyword: this.state.keyword,
            }}
          >
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <div
                    className="sweet-loading"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: '100%',
                      minHeight: '10rem',
                      margin: '0 auto',
                    }}
                  >
                    <DotLoader
                      sizeUnit={'px'}
                      size={25}
                      color={'#8ac691'}
                      loading={!this.state.isLoaded}
                    />
                  </div>
                );
              if (error) return <div>Error: {error.message}</div>;
              let totalItems = data.search.results.totalCount;
              return (
                <>
                  <ul>
                    {data && data.search.results.items ? (
                      data.search.results.items.map((result, index) => {
                        return (
                          <li key={'post' + index}>
                            {result.templateName === 'PostRoute' ? ( // for articles
                              <NavLinkAdv to={result.item.url}>
                                <img
                                  width={360}
                                  height={200}
                                  src={
                                    result.item.postThumbnailImageMedium &&
                                    result.item.postThumbnailImageMedium.src
                                  }
                                  alt={
                                    result.item.postThumbnailImageMedium &&
                                    result.item.postThumbnailImageMedium.alt
                                  }
                                />
                              </NavLinkAdv>
                            ) : (
                              result.templateName === 'ProductRoute' && ( // for products
                                <NavLinkAdv to={result.item.url}>
                                  <img
                                    width={360}
                                    height={200}
                                    src={result.item.image1 && result.item.image1.src}
                                    alt={result.item.image1 && result.item.image1.alt}
                                  />
                                </NavLinkAdv>
                              )
                            )}
                            <h2>
                              {result.templateName === 'PostRoute' ? ( // for articles
                                <NavLinkAdv to={result.item && result.item.url}>
                                  {result.item.postTitle && result.item.postTitle.value}
                                </NavLinkAdv>
                              ) : (
                                result.templateName === 'ProductRoute' && ( // for products
                                  <NavLinkAdv to={result.item && result.item.url}>
                                    {result.item.title && result.item.title.value}
                                  </NavLinkAdv>
                                )
                              )}
                            </h2>
                            {result.templateName === 'PostRoute' ? ( // for articles
                              <p>
                                {result.item.postShortDescription &&
                                  result.item.postShortDescription.value}
                              </p>
                            ) : (
                              result.templateName === 'ProductRoute' && ( // for products
                                <p>
                                  {result.item.productShortDescription &&
                                    result.item.productShortDescription.value}
                                </p>
                              )
                            )}
                            <div className="date">
                              <i className="fa fa-calendar"></i>
                              <Moment format="MMM DD, YYYY">
                                {result.item.date && result.item.date.value}
                              </Moment>
                            </div>
                            <NavLinkAdv to={result.item && result.item.url}>Read More</NavLinkAdv>
                          </li>
                        );
                      })
                    ) : (
                      <p>{i18next.t('no-results-found')}</p>
                    )}
                  </ul>
                  <Pagination
                    totalItems={totalItems}
                    nbOfItemsToShow={this.state.nbOfItemsToShow}
                    onChange={this.handleChange}
                    currentPage={this.state.currentPage}
                  />
                </>
              );
            }}
          </Query>
        </div>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
            i18next.t('component')}
        </div>
      );
    }
  }
}

export default withSitecoreContext()(GlobalSearch);

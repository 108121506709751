import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CarouselHome.scss';
import { Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

class CarouselHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      activeDot: 0,
    };
    this.setActiveDot = this.setActiveDot.bind(this);
  }

  setActiveDot(dot) {
    this.setState({
      activeDot: dot,
    });
  }

  render() {
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const datasource = this.props.fields || {};
    if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
      const slidesList = this.props.fields.Slides;

      const designVariant =
        typeof this.props.fields.designVariant === 'undefined'
          ? ''
          : this.props.fields.designVariant.value;

      return (
        <div className={'section ' + designVariant}>
          <div className="container-fluid">
            <div className="row homeCarousel" style={{ maxHeight: '505px' }}>
              <Slider {...settings} style={{ maxHeight: '505px' }}>
                {slidesList &&
                  slidesList.map((listItem, index) => (
                    <li key={`listItem-${index}`} style={{ maxHeight: '505px' }}>
                      {listItem.fields.Banner.value.src ? (
                        <div
                          className="imgContainer"
                          style={{
                            backgroundImage: `url(` + listItem.fields.Banner.value.src + `)`,
                          }}
                        >
                          {listItem.fields.Subtitle.value !== '' && (
                            <div className="wrapperCenter">
                              <div className="tp-caption business_small_white tp-resizeme subtitle">
                                <Text field={listItem.fields.Subtitle} />
                              </div>
                            </div>
                          )}
                          {listItem.fields.Title.value !== '' && (
                            <div className="wrapperCenter">
                              <div className="tp-caption business_big_white tp-resizeme title">
                                <RichText field={listItem.fields.Title} />
                              </div>
                            </div>
                          )}
                          <div className="wrapperCenter">
                            {listItem.fields.ButtonLeft.value !== '' && (
                              <div className="btnWrapper">
                                <NavLinkAdv
                                  to={
                                    listItem.fields.ButtonLeft &&
                                    listItem.fields.ButtonLeft.value.href
                                  }
                                  className="btn btn-white-outline"
                                >
                                  {listItem.fields.ButtonLeft.value.text
                                    ? listItem.fields.ButtonLeft.value.text
                                    : 'Lorem Ipsum'}
                                </NavLinkAdv>
                              </div>
                            )}
                            {listItem.fields.ButtonRight.value !== '' && (
                              <div className="btnWrapper">
                                <NavLinkAdv
                                  to={
                                    listItem.fields.ButtonRight &&
                                    listItem.fields.ButtonRight.value.href
                                  }
                                  className="btn btn-white-outline"
                                >
                                  {listItem.fields.ButtonRight.value.text
                                    ? listItem.fields.ButtonRight.value.text
                                    : 'Lorem Ipsum'}
                                </NavLinkAdv>
                              </div>
                            )}
                          </div>
                          {/*}*/}
                        </div>
                      ) : (
                        <div>
                          {listItem.fields.LinkVideo.value.href &&
                            listItem.fields.LinkVideo.value.linktype === 'external' && (
                              <iframe
                                title="youtube"
                                width="1920"
                                height="505"
                                src={listItem.fields.LinkVideo.value.url.replace(
                                  'https://youtu.be/',
                                  'https://www.youtube.com/embed/'
                                )}
                              />
                            )}
                          {listItem.fields.LinkVideo.value.href &&
                            listItem.fields.LinkVideo.value.linktype === 'media' && (
                              <video
                                style={{ width: '100%', height: '505px' }}
                                className="video-fluid"
                                loop
                                autoPlay
                                muted
                              >
                                <source
                                  src={listItem.fields.LinkVideo.value.href}
                                  type="video/mp4"
                                />
                              </video>
                            )}
                          {listItem.fields.LinkVideo.value.linktype !== 'external' && (
                            <div>
                              {listItem.fields.Subtitle.value !== '' && (
                                <div className="wrapperCenter">
                                  <div className="tp-caption business_small_white tp-resizeme subtitle">
                                    <Text field={listItem.fields.Subtitle} />
                                  </div>
                                </div>
                              )}
                              {listItem.fields.Title.value !== '' && (
                                <div className="wrapperCenter">
                                  <div className="tp-caption business_big_white tp-resizeme title">
                                    <RichText field={listItem.fields.Title} />
                                  </div>
                                </div>
                              )}
                              <div className="wrapperCenter">
                                {listItem.fields.ButtonLeft.value.href !== '' && (
                                  <div className="btnWrapper">
                                    <NavLinkAdv
                                      to={
                                        listItem.fields.ButtonLeft &&
                                        listItem.fields.ButtonLeft.value.href
                                      }
                                      className="btn btn-white-outline"
                                    >
                                      {listItem.fields.ButtonLeft.value.text
                                        ? listItem.fields.ButtonLeft.value.text
                                        : 'Lorem Ipsum'}
                                    </NavLinkAdv>
                                  </div>
                                )}
                                {listItem.fields.ButtonRight.value.href !== '' && (
                                  <div className="btnWrapper">
                                    <NavLinkAdv
                                      to={
                                        listItem.fields.ButtonRight &&
                                        listItem.fields.ButtonRight.value.href
                                      }
                                      className="btn btn-white-outline"
                                    >
                                      {listItem.fields.ButtonRight.value.text
                                        ? listItem.fields.ButtonRight.value.text
                                        : 'Lorem Ipsum'}
                                    </NavLinkAdv>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </li>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
            i18next.t('component')}
        </div>
      );
    }
  }
}

export default withSitecoreContext()(CarouselHome);

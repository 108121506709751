import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import './HeadingBanner.scss';

const HeadingBanner = (props) => {
  return (
    <div
      className="headingBanner"
      style={{
        backgroundImage: `url(` + props.fields.backgroundImage.value.src + `)`,
      }}
    >
      <Text field={props.fields.title} tag="h1" />
      <span className="subtitle">
        <Text field={props.fields.subtitle} />{' '}
      </span>
      <div className="whiteBlock"></div>
    </div>
  );
};

export default HeadingBanner;

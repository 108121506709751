import React from 'react';
import NavLinkAdv from '../../utils/NavLinkAdv';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import './FeaturedPostDynamic.scss';

export default function FeaturedPost({ post, listItem }) {
  return (
    <li className="featuredPostDynamic">
      <NavLinkAdv to={post.item && post.item.url}>
        <div className="imgContainer">
          <Image loading="lazy" field={listItem.fields.PostThumbnailImageMedium} />
          <div className="decoration"></div>
        </div>
        <h3>{listItem.fields.PostTitle.value}</h3>
        <RichText field={listItem.fields.PostShortDescription} className="postShortDescription" />
      </NavLinkAdv>
    </li>
  );
}

import React from 'react';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './blockImgLeft.scss';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

const BlockImageLeft = (props) => {
  const datasource = props.fields || {};
  if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
    return (
      <div
        className={'section parallax-background-3 ' + props.fields.designVariant.value + ''}
        style={{ backgroundImage: `url(` + props.fields.BackgroundImage.value.src + `)` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-6 col-md-6 hidden-sm hidden-xs  ">
              <div className="image">
                <Image loading="lazy" field={props.fields.Image} className={'blockImgLeft'} />
              </div>
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 pt-3 pb-5">
              <Text field={props.fields.Title} tag="h2" className="white mb-0  " />
              <Text tag="p" className="white mt-3 mb-5  " field={props.fields.Description} />
              <NavLinkAdv
                to={props.fields.Link && props.fields.Link.value.href}
                className="btn btn-white-outline btn-align-left btn-icon btn-icon-slide-in btn-icon-right  "
              >
                <span>{props.fields.Link.value.title}</span>
                <i className="fa fa-long-arrow-right"></i>
              </NavLinkAdv>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="component-no-datasource">
        {i18next.t('no-datasource') +
          ' ' +
          props.rendering.componentName +
          ' ' +
          i18next.t('component')}
      </div>
    );
  }
};

export default withSitecoreContext()(BlockImageLeft);

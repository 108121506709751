import React from 'react';
import { HashLink } from 'react-router-hash-link';
import ReactComponent from '../../ReactComponent';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class NavLinkAdv extends ReactComponent {
  render() {
    if (this.props.to.startsWith('http') || this.props.to.startsWith('mailto')) {
      return (
        <a
          href={this.props.to}
          className={this.props.className}
          key={this.props.key}
          target="_blank"
          rel="noopener noreferrer"
          style={this.props.style}
        >
          {this.props.children}
        </a>
      );
    } else {
      return (
        <HashLink
          to={this.getUrlEmbeded(this.props.to)}
          className={this.props.className}
          key={this.props.key}
          style={this.props.style}
        >
          {this.props.children}
        </HashLink>
      );
    }
  }
}

export default withSitecoreContext()(NavLinkAdv);
